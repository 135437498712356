import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getDashboardCount } from "../../services/genericService";
import Card from "../../components/Card/Card";
import "./ManageHierarchy.css";
import Sidebar from "../Sidebar/Sidebar";
import { SidebarContext } from "../Sidebar/SidebarContext";

const ManageHierarchy = ({ active }) => {
  const [counts, setCounts] = useState({
    families: 0,
    categories: 0,
    divisions: 0,
    types: 0,
    subCategories: 0,
  });
  const { expanded } = useContext(SidebarContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCounts();
  }, []);

  const fetchCounts = async () => {
    try {
      const response = await getDashboardCount();
      setCounts(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching dashboard counts:", error);
    }
  };

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <h1 className="text-4xl font-bold text-center mb-6">Home</h1>

        <div className="cards">
          <Card className="onboard" onClick={() => navigate("/home/family")}>
            <h2 className="card-text">Families</h2>
            <p className="count">{counts.product_family_count}</p>
          </Card>

          <Card className="onboard" onClick={() => navigate("/home/category")}>
            <p className="card-text">Categories</p>
            <p className="count">{counts.product_category_count}</p>
          </Card>

          <Card className="onboard" onClick={() => navigate("/home/division")}>
            <p className="card-text">Divisions</p>
            <p className="count">{counts.product_division_count}</p>
          </Card>

          <Card className="onboard" onClick={() => navigate("/home/types")}>
            <p className="card-text">Types</p>
            <p className="count">{counts.product_type_count}</p>
          </Card>

          <Card
            className="onboard"
            onClick={() => navigate("/home/subcategory")}
          >
            <p className="card-text">Sub-Categories</p>
            <p className="count">{counts.product_subcategory_count}</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ManageHierarchy;

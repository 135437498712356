import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { SidebarContext } from "../Sidebar/SidebarContext";
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./GenericDetails.css";

const GenericDetails = ({ active }) => {
  const location = useLocation();
  const { entity } = location.state || {};
  const { expanded } = useContext(SidebarContext);

  if (!entity) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Entity not found
        </Typography>
      </Container>
    );
  }

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          className="header-box"
        >
          <Typography variant="h4" gutterBottom className="header-text">
            Entity Details
          </Typography>
        </Box>
        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell-header">Field</TableCell>
                <TableCell className="table-cell-header">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(entity).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell className="table-cell-key">
                    {key.replace(/_/g, " ")}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {typeof value === "object" ? JSON.stringify(value) : value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default GenericDetails;

import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { createNewProduct } from "../../services/productService";
import {
  Button,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./ProductForm.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";

const ProductForm = ({ active }) => {
  const [newProduct, setNewProduct] = useState({
    product_name: "",
    utility_id: "",
    product_id: "",
    item_id: "",
    product_description: "",
    sources: "",
    presentation: "",
    pack_size: "",
    product_image: null,
    practice_method: "",
    formulation: "",
    brand_name: "",
    division: "",
  });
  const { expanded } = useContext(SidebarContext);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProduct({ ...newProduct, product_image: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      formData.append(key, newProduct[key]);
    });
    try {
      await createNewProduct(formData);
      toast.success("Product created successfully!");
      navigate("/view-products");
    } catch (error) {
      console.error("Error creating new product:", error);
      toast.error("Error creating product. Please try again.");
    }
  };

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography variant="h4" gutterBottom className="header">
          Add New Product
        </Typography>
        <Card elevation={3} className="card">
          <CardContent>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <label>Product Name</label>
                  <TextField
                    fullWidth
                    name="product_name"
                    value={newProduct.product_name}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Utility ID</label>
                  <TextField
                    fullWidth
                    name="utility_id"
                    value={newProduct.utility_id}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Product ID</label>
                  <TextField
                    fullWidth
                    name="product_id"
                    value={newProduct.product_id}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Item ID</label>
                  <TextField
                    fullWidth
                    name="item_id"
                    value={newProduct.item_id}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Product Description</label>
                  <TextField
                    fullWidth
                    name="product_description"
                    value={newProduct.product_description}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Sources</label>
                  <TextField
                    fullWidth
                    name="sources"
                    value={newProduct.sources}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12}>
                  <label>Presentation</label>
                  <TextField
                    fullWidth
                    name="presentation"
                    value={newProduct.presentation}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Pack Size</label>
                  <TextField
                    fullWidth
                    name="pack_size"
                    value={newProduct.pack_size}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Practice Method</label>
                  <TextField
                    fullWidth
                    name="practice_method"
                    value={newProduct.practice_method}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Formulation</label>
                  <TextField
                    fullWidth
                    name="formulation"
                    value={newProduct.formulation}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Brand Name</label>
                  <TextField
                    fullWidth
                    name="brand_name"
                    value={newProduct.brand_name}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Division</label>
                  <TextField
                    fullWidth
                    name="division"
                    value={newProduct.division}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    className="textField"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Upload Product Image</label>
                  <button
                    variant="contained"
                    component="label"
                    className="uploadButton"
                  >
                    Upload Image
                    <input
                      type="file"
                      hidden
                      name="product_image"
                      onChange={handleImageChange}
                    />
                  </button>

                  {imagePreview && (
                    <Box mt={2}>
                      <img
                        src={imagePreview}
                        alt="Product Preview"
                        className="imagePreview"
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" mt={3}>
                    <button
                      className="saveButton"
                      type="submit"
                      variant="contained"
                    >
                      Save Product
                    </button>
                    <button
                      variant="outlined"
                      onClick={() => navigate("/view-products")}
                      className="cancelButton"
                    >
                      Cancel
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ProductForm;

import axiosInstance from "./axiosInstance";

const createNewProduct = (newproduct) => {
  return axiosInstance.post("newproduct/", newproduct);
};

const getNewProduct = () => {
  return axiosInstance.get("/list-newproducts/");
};

const updateNewProduct = (id, product) => {
  return axiosInstance.put(`newproduct/${id}/`, product);
};

const deleteNewProduct = (id) => {
  return axiosInstance.delete(`newproduct/${id}/`);
};
const getDetails = (id) => {
  return axiosInstance.get(`newproduct/${id}/`);
};

export {
  createNewProduct,
  getNewProduct,
  updateNewProduct,
  deleteNewProduct,
  getDetails,
};

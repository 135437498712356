import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const ProductDetailsModal = ({ open, handleClose, product }) => {
    if (!product) return null;

    const imageUrl = typeof product.image === 'string' ? product.image : product.image ? URL.createObjectURL(product.image) : null;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="product-details-title"
            aria-describedby="product-details-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '1px solid #ddd',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center'
                }}
            >
                <Typography id="product-details-title" variant="h5" component="h2" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {product.name}
                </Typography>
                <Typography id="product-details-description" sx={{ mt: 2, color: 'text.secondary' }}>
                    Family: {product.family ? product.family.value : 'N/A'}
                </Typography>
                <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                    Category: {product.category ? product.category.value : 'N/A'}
                </Typography>
                <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                    Division: {product.division ? product.division.value : 'N/A'}
                </Typography>
                <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                    Type: {product.type ? product.type.value : 'N/A'}
                </Typography>
                <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                    Sub-Category: {product.sub_category ? product.sub_category.value : 'N/A'}
                </Typography>
                {imageUrl && (
                    <Box sx={{ mt: 3 }}>
                        <img src={imageUrl} alt={product.name} style={{ width: '100%', borderRadius: '8px' }} />
                    </Box>
                )}
                <Button onClick={handleClose} sx={{ mt: 3 }} variant="contained" color="primary">
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default ProductDetailsModal;

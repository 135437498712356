import React, { useState, useEffect, useContext } from "react";
import {
  getProducts as fetchProducts,
  deleteProduct as removeProduct,
} from "../../services/genericService";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import "./GenericList.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";
const GenericList = ({ active }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState("");
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);
  useEffect(() => {
    fetchProductsData();
  }, []);

  const fetchProductsData = async () => {
    try {
      const response = await fetchProducts();
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeProduct(id);
      fetchProductsData();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleEdit = (product) => {
    navigate("/add-product", { state: { product } });
  };

  const handleRowClick = (product) => {
    navigate(`/details/${product.id}`, { state: { entity: product } });
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedProducts = sortKey
    ? [...filteredProducts].sort((a, b) => {
        if (a[sortKey] < b[sortKey]) return -1;
        if (a[sortKey] > b[sortKey]) return 1;
        return 0;
      })
    : filteredProducts;

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Box className="header-box">
          <button variant="contained" className="export-button">
            Export CSV
          </button>
          <Typography variant="h4" className="header-title">
            Generic List
          </Typography>
          <button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/add-product")}
            className="add-button"
          >
            Add Generic
          </button>
        </Box>
        <Grid container spacing={2} alignItems="center" className="filter-box">
          <Grid item xs={12} sm={6}>
            <input
              placeholder="Search Products"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </Grid>
          <Grid item xs={12} sm={6} className="sort-select-container">
            <select
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
              className="sort-select"
            >
              <option value="">None</option>
              <option value="name">Name</option>
              <option value="family">Family</option>
              <option value="category">Category</option>
              <option value="division">Division</option>
              <option value="type">Type</option>
              <option value="sub_category">Sub-Category</option>
            </select>
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell className="table-cell-header">ID</TableCell>
                <TableCell className="table-cell-header">Name</TableCell>
                <TableCell className="table-cell-header">Family</TableCell>
                <TableCell className="table-cell-header">Category</TableCell>
                <TableCell className="table-cell-header">Division</TableCell>
                <TableCell className="table-cell-header">Type</TableCell>
                <TableCell className="table-cell-header">
                  Sub-Category
                </TableCell>
                <TableCell className="table-cell-header">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedProducts.map((product, index) => (
                <TableRow
                  key={product.id}
                  hover
                  onClick={() => handleRowClick(product)}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    {product.family ? product.family.value : "N/A"}
                  </TableCell>
                  <TableCell>
                    {product.category ? product.category.value : "N/A"}
                  </TableCell>
                  <TableCell>
                    {product.division ? product.division.value : "N/A"}
                  </TableCell>
                  <TableCell>
                    {product.type ? product.type.value : "N/A"}
                  </TableCell>
                  <TableCell>
                    {product.sub_category ? product.sub_category.value : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Box className="action-buttons">
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(product);
                          }}
                          className="edit-button"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(product.id);
                          }}
                          className="delete-button"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default GenericList;

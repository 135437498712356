import React, { useState, useEffect, useContext } from "react";
import {
  createProduct,
  updateProduct,
  getChoices,
  getSafetyList,
  getDosageList,
} from "../../services/genericService";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./GenericForm.css";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";

const GenericForm = ({ active }) => {
  const [product, setProduct] = useState({
    name: "",
    family: "",
    category: "",
    division: "",
    type: "",
    sub_category: "",
    image: null,
    use: "",
    mechanism_of_action: "",
    handling_warnings: "",
    safety: [],
    adverse_reactions: "",
    dosage: [],
    cautions: "",
    drug_interactions: "",
    availability: "",
    drug_interaction_comments: [],
    safety_comments: [],
    detailing_dosing_comments: [],
  });

  const [choices, setChoices] = useState({
    families: [],
    categories: [],
    divisions: [],
    types: [],
    subCategories: [],
  });

  const [safetyOptions, setSafetyOptions] = useState([]);
  const [detailingDosingOptions, setDetailingDosingOptions] = useState([]);
  const [openHierarchy, setOpenHierarchy] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const selectedProduct = location.state?.product;

  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchChoices();
    fetchSafetyOptions();
    fetchDetailingDosingOptions();
    if (selectedProduct) {
      setProduct({
        ...selectedProduct,
        family: selectedProduct.family?.id || "",
        category: selectedProduct.category?.id || "",
        division: selectedProduct.division?.id || "",
        type: selectedProduct.type?.id || "",
        sub_category: selectedProduct.sub_category?.id || "",
        safety: selectedProduct.safety || [],
        dosage: selectedProduct.dosage || [],
        drug_interaction_comments: selectedProduct.drug_interaction_comments || [],
        safety_comments: selectedProduct.safety_comments || [],
        detailing_dosing_comments: selectedProduct.detailing_dosing_comments || [],
      });
    }
  }, [selectedProduct]);

  const fetchChoices = async () => {
    try {
      const response = await getChoices();
      setChoices(response);
    } catch (error) {
      console.error("Error fetching choices:", error);
    }
  };

  const fetchSafetyOptions = async () => {
    try {
      const response = await getSafetyList();
      setSafetyOptions(response.data);
    } catch (error) {
      console.error("Error fetching safety options:", error);
    }
  };

  const fetchDetailingDosingOptions = async () => {
    try {
      const response = await getDosageList();
      setDetailingDosingOptions(response.data);
    } catch (error) {
      console.error("Error fetching detailing dosing options:", error);
    }
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleImageChange = (e) => {
    setProduct({ ...product, image: e.target.files[0] });
  };

  const handleSafetyChange = (event) => {
    const {
      target: { value },
    } = event;
    setProduct({
      ...product,
      safety: value,
    });
  };

  const handleDetailingDosingChange = (event) => {
    const {
      target: { value },
    } = event;
    setProduct({
      ...product,
      dosage: value,
    });
  };

  const handleCommentChange = (index, value, type) => {
    if (type === "drug_interaction") {
      const updatedComments = [...product.drug_interaction_comments];
      updatedComments[index] = value;
      setProduct({ ...product, drug_interaction_comments: updatedComments });
    } else if (type === "safety") {
      const updatedComments = [...product.safety_comments];
      updatedComments[index] = value;
      setProduct({ ...product, safety_comments: updatedComments });
    } else if (type === "dosage") {
      const updatedComments = [...product.detailing_dosing_comments];
      updatedComments[index] = value;
      setProduct({ ...product, detailing_dosing_comments: updatedComments });
    }
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    Object.entries(product).forEach(([key, value]) => {
      if (value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, value);
        }
      }
    });

    try {
      if (selectedProduct) {
        await updateProduct(selectedProduct.id, formData);
      } else {
        await createProduct(formData);
      }
      setProduct({
        name: "",
        family: "",
        category: "",
        division: "",
        type: "",
        sub_category: "",
        image: null,
        use: "",
        mechanism_of_action: "",
        handling_warnings: "",
        safety: [],
        adverse_reactions: "",
        dosage: [],
        cautions: "",
        drug_interactions: "",
        availability: "",
        drug_interaction_comments: [],
        safety_comments: [],
        detailing_dosing_comments: [],
      });
      navigate("/generic-list");
    } catch (error) {
      console.error("Error creating/updating product:", error);
    }
  };

  const filteredCategories = choices.categories.filter(
    (category) => category.parent_details.id === product.family
  );
  const filteredDivisions = choices.divisions.filter(
    (division) => division.parent_details.id === product.category
  );
  const filteredTypes = choices.types.filter(
    (type) => type.parent_details.id === product.division
  );
  const filteredSubCategories = choices.subCategories.filter(
    (subCategory) => subCategory.parent_details.id === product.type
  );

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography variant="h4" gutterBottom className="generic-form-header">
          {selectedProduct ? "Edit Product" : "Add New Generic Product"}
        </Typography>
        <Card elevation={3} className="generic-form-card">
          <CardContent>
            <form onSubmit={handleProductSubmit} encType="multipart/form-data">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label>Name</label>
                  <TextField
                    fullWidth
                    name="name"
                    value={product.name}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    className="generic-form-textfield"
                  />
                </Grid>

                <Grid item xs={12} md={6} display="flex" alignItems="flex-end">
                  <Button
                    onClick={() => setOpenHierarchy(true)}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Hierarchy
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <label>Image</label>
                  <input
                    name="image"
                    type="file"
                    onChange={handleImageChange}
                    className="generic-form-file-input"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Safety</label>
                  <Select
                    fullWidth
                    multiple
                    value={product.safety}
                    onChange={handleSafetyChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              safetyOptions.find((opt) => opt.id === value)
                                ?.name || ""
                            }
                          />
                        ))}
                      </Box>
                    )}
                    className="generic-form-select"
                  >
                    {safetyOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {product.safety.map((optionId, index) => (
                    <Box key={index} mt={2}>
                      <TextField
                        fullWidth
                        label={`Comment for ${
                          safetyOptions.find((opt) => opt.id === optionId)
                            ?.name || ""
                        }`}
                        variant="outlined"
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={product.safety_comments[index] || ""}
                        onChange={(e) =>
                          handleCommentChange(index, e.target.value, "safety")
                        }
                        className="generic-form-textfield"
                      />
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>Detailing/Dosing</label>
                  <Select
                    fullWidth
                    multiple
                    value={product.dosage}
                    onChange={handleDetailingDosingChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              detailingDosingOptions.find(
                                (opt) => opt.id === value
                              )?.name || ""
                            }
                          />
                        ))}
                      </Box>
                    )}
                    className="generic-form-select"
                  >
                    {detailingDosingOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {product.dosage.map((optionId, index) => (
                    <Box key={index} mt={2}>
                      <TextField
                        fullWidth
                        label={`Comment for ${
                          detailingDosingOptions.find(
                            (opt) => opt.id === optionId
                          )?.name
                        }`}
                        variant="outlined"
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={product.detailing_dosing_comments[index] || ""}
                        onChange={(e) =>
                          handleCommentChange(index, e.target.value, "dosage")
                        }
                        className="generic-form-textfield"
                      />
                    </Box>
                  ))}
                </Grid>
                <div className="use-action">
                  <div className="use">
                    <label>Use</label>
                    <TextField
                      fullWidth
                      name="use"
                      value={product.use}
                      onChange={handleProductChange}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={10}
                      className="generic-form-textfield"
                    />
                  </div>

                  <div className="action">
                    <label>Mechanism of Action</label>
                    <TextField
                      fullWidth
                      name="mechanism_of_action"
                      value={product.mechanism_of_action}
                      onChange={handleProductChange}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={10}
                      className="generic-form-textfield"
                    />
                  </div>
                </div>
                <div className="warning-reactions">
                  <div className="warning">
                    <label>Handling Warnings</label>
                    <TextField
                      fullWidth
                      name="handling_warnings"
                      value={product.handling_warnings}
                      onChange={handleProductChange}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={10}
                      className="generic-form-textfield"
                    />
                  </div>

                  <div className="reactions">
                    <label>Adverse Reactions</label>
                    <TextField
                      fullWidth
                      name="adverse_reactions"
                      value={product.adverse_reactions}
                      onChange={handleProductChange}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={10}
                      className="generic-form-textfield"
                    />
                  </div>
                </div>
              </Grid>

              <div className="cautions-drugs">
                <div>
                  <div className="cautions">
                    <label>Cautions</label>
                    <TextField
                      fullWidth
                      name="cautions"
                      value={product.cautions}
                      onChange={handleProductChange}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={10}
                      className="generic-form-textfield"
                    />
                  </div>
                  <div className="availability">
                    <label>Availability</label>
                    <TextField
                      fullWidth
                      name="availability"
                      value={product.availability}
                      onChange={handleProductChange}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={10}
                      className="generic-form-textfield"
                    />
                  </div>
                </div>
                <div className="drugs">
                  <label>Drug Interactions</label>
                  <TextField
                    fullWidth
                    name="drug_interactions"
                    value={product.drug_interactions}
                    onChange={handleProductChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={10}
                    className="generic-form-textfield"
                  />
                  {product.drug_interactions
                    .split(",")
                    .map((interaction, index) => (
                      <Box key={index} mt={2}>
                        <TextField
                          fullWidth
                          label={`Comment for ${interaction.trim()}`}
                          variant="outlined"
                          multiline
                          minRows={2}
                          maxRows={10}
                          value={product.drug_interaction_comments[index] || ""}
                          onChange={(e) =>
                            handleCommentChange(
                              index,
                              e.target.value,
                              "drug_interaction"
                            )
                          }
                          className="generic-form-textfield"
                        />
                      </Box>
                    ))}
                </div>
              </div>

              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <button
                      type="submit"
                      variant="contained"
                      className="save-button"
                    >
                      Save Product
                    </button>
                    <button
                      variant="outlined"
                      onClick={() => navigate("/generic-list")}
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>

        <Dialog
          open={openHierarchy}
          onClose={() => setOpenHierarchy(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Hierarchy</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the appropriate options for Family, Category,
              Division, Type, and Sub-Category.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <label>Family</label>
                <Select
                  fullWidth
                  name="family"
                  value={product.family}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                >
                  <MenuItem value="">Select Family</MenuItem>
                  {choices.families.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Category</label>
                <Select
                  fullWidth
                  name="category"
                  value={product.category}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.family}
                >
                  <MenuItem value="">Select Category</MenuItem>
                  {filteredCategories.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Division</label>
                <Select
                  fullWidth
                  name="division"
                  value={product.division}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.category}
                >
                  <MenuItem value="">Select Division</MenuItem>
                  {filteredDivisions.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Type</label>
                <Select
                  fullWidth
                  name="type"
                  value={product.type}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.division}
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {filteredTypes.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <label>Sub-Category</label>
                <Select
                  fullWidth
                  name="sub_category"
                  value={product.sub_category}
                  onChange={handleProductChange}
                  variant="outlined"
                  className="generic-form-select"
                  disabled={!product.type}
                >
                  <MenuItem value="">Select Sub-Category</MenuItem>
                  {filteredSubCategories.map((choice) => (
                    <MenuItem key={choice.id} value={choice.id}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHierarchy(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default GenericForm;

import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { getNewProduct, updateNewProduct } from "../../services/productService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import ProductDetailsModal from "../ProductDetails/ProductDetailsModal";
import "./ViewProducts.css";
import { SidebarContext } from "../Sidebar/SidebarContext";

const ViewProducts = ({ active }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [editProductId, setEditProductId] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await getNewProduct();
      console.log("products are", response.data);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleEditClick = (product) => {
    setEditProductId(product.id);
    setEditedProduct({ ...product });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleSaveClick = async (id) => {
    try {
      await updateNewProduct(id, editedProduct);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === id ? editedProduct : product
        )
      );
      setEditProductId(null);
      setEditedProduct({});
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleCancelClick = () => {
    setEditProductId(null);
    setEditedProduct({});
  };

  const handleRowClick = (product) => {
    navigate(`/product-details/${product.id}`, { state: { product } });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <button variant="contained" className="export-button">
            Export CSV
          </button>
          <Typography variant="h4" gutterBottom className="view-products-title">
            View Products
          </Typography>
          <div className="buttons">
            <button
              variant="contained"
              onClick={() => navigate("/add-new-product")}
              className="add-button"
            >
              Add New Product
            </button>
          </div>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Product Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Utility ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Product ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Item ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Sources</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => (
                <TableRow
                  key={product.id}
                  hover
                  onClick={() => handleRowClick(product)}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <TextField
                        name="product_name"
                        value={editedProduct.product_name}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.product_name
                    )}
                  </TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <TextField
                        name="utility_id"
                        value={editedProduct.utility_id}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.utility_id
                    )}
                  </TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <TextField
                        name="product_id"
                        value={editedProduct.product_id}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.product_id
                    )}
                  </TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <TextField
                        name="item_id"
                        value={editedProduct.item_id}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.item_id
                    )}
                  </TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <TextField
                        name="product_description"
                        value={editedProduct.product_description}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.product_description
                    )}
                  </TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <TextField
                        name="sources"
                        value={editedProduct.sources}
                        onChange={handleInputChange}
                        size="small"
                      />
                    ) : (
                      product.sources
                    )}
                  </TableCell>
                  <TableCell>
                    {editProductId === product.id ? (
                      <Box display="flex" justifyContent="center" gap={1}>
                        <Tooltip title="Save">
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveClick(product.id);
                            }}
                            className="save-icon-button"
                          >
                            <SaveIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelClick();
                            }}
                            className="cancel-icon-button"
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="center" gap={1}>
                        <Tooltip title="Edit">
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(product);
                            }}
                            className="edit-icon-button"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ProductDetailsModal
          open={modalOpen}
          handleClose={handleCloseModal}
          product={selectedProduct}
        />
      </div>
    </div>
  );
};

export default ViewProducts;

import React, { useContext, useEffect, useState } from "react";
import { deleteNewProduct, getDetails } from "../../services/productService";
import {
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { SidebarContext } from "../Sidebar/SidebarContext";
import Sidebar from "../Sidebar/Sidebar";

const ProductDetails = ({ active }) => {
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { expanded } = useContext(SidebarContext);

  useEffect(() => {
    fetchProductDetails(id);
  }, [id]);

  const fetchProductDetails = async (id) => {
    try {
      const response = await getDetails(id);
      setProduct(response.data);
      console.log("Product data: ", response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product details:", error);
      toast.error("Error fetching product details. Please try again.");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log("Attempting to delete product with ID:", id);
      await deleteNewProduct(id);
      toast.success("Product deleted successfully!");
      navigate("/view-products"); // Ensure this route exists in your application
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Error deleting product. Please try again.");
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!product) {
    return (
      <Container>
        <Typography variant="h5">Product not found</Typography>
      </Container>
    );
  }

  return (
    <div className="layout">
      <Sidebar active={active} />

      <div className={`mh-container ${expanded ? "expanded" : "collapsed"}`}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#2c3e50",
            fontWeight: "bold",
            textAlign: "center",
            mb: 4,
          }}
        >
          Product Details
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box width="100%">
              <TableContainer
                component={Paper}
                sx={{ borderRadius: "12px", mb: 4 }}
              >
                <Table>
                  <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Field</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(product).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {key.replace(/_/g, " ")}
                        </TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                gap={2}
                mb={4}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDelete(product.id)}
                  sx={{
                    borderColor: "#e74c3c",
                    color: "#e74c3c",
                    "&:hover": { backgroundColor: "#f2dede" },
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ProductDetails;
